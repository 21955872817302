import React, {useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
    Typography,
    Tooltip,
    Skeleton,
    CircularProgress
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

const InteractionSettings = ({ formData, setFormData, creatorInfo, disabled, isLoading }) => {
    // Effect to force interactions to false if not allowed by account
    React.useEffect(() => {
        const updatedFormData = { ...formData };
        let hasChanges = false;

        // Check each interaction setting
        if (creatorInfo?.comment_disabled && formData.allow_comment) {
            updatedFormData.allow_comment = false;
            hasChanges = true;
        }
        if (creatorInfo?.duet_disabled && formData.allow_duet) {
            updatedFormData.allow_duet = false;
            hasChanges = true;
        }
        if (creatorInfo?.stitch_disabled && formData.allow_stitch) {
            updatedFormData.allow_stitch = false;
            hasChanges = true;
        }

        // Only update if changes were made
        if (hasChanges) {
            setFormData(updatedFormData);
            console.debug('Interaction settings adjusted based on creator capabilities:', {
                original: formData,
                updated: updatedFormData,
                creatorInfo
            });
        }
    }, [creatorInfo, formData, setFormData]);

    const interactionControls = [
        {
            name: 'allow_comment',
            label: 'Allow Comments',
            description: 'Enable or disable comments on your video',
            disabled: creatorInfo?.comment_disabled,
            tooltip: creatorInfo?.comment_disabled ?
                'Comments are not available for your account type' : undefined
        },
        {
            name: 'allow_duet',
            label: 'Allow Duets',
            description: 'Allow others to create duets with your video',
            disabled: creatorInfo?.duet_disabled,
            tooltip: creatorInfo?.duet_disabled ?
                'Duets are not available for your account type' : undefined
        },
        {
            name: 'allow_stitch',
            label: 'Allow Stitches',
            description: 'Allow others to stitch your video into their content',
            disabled: creatorInfo?.stitch_disabled,
            tooltip: creatorInfo?.stitch_disabled ?
                'Stitches are not available for your account type' : undefined
        }
    ];

    return (
        <FormControl component="fieldset" disabled={disabled}>
            <FormLabel component="legend" id="interaction-settings-label">
                Video Interaction Settings
            </FormLabel>
            <FormGroup aria-labelledby="interaction-settings-label">
                {isLoading ? (
                    <>
                        <Skeleton variant="rectangular" height={40} />
                        <Skeleton variant="rectangular" height={40} />
                        <Skeleton variant="rectangular" height={40} />
                    </>
                ) : (
                    interactionControls.map((control) => (
                        <Tooltip key={control.name} title={control.tooltip || ''}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formData[control.name]}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            [control.name]: e.target.checked
                                        }))}
                                        disabled={control.disabled || disabled}
                                        inputProps={{
                                            'aria-label': control.label,
                                            'aria-describedby': `${control.name}-description`
                                        }}
                                    />
                                }
                                label={
                                    <Box>
                                        <Typography>{control.label}</Typography>
                                        <Typography 
                                            id={`${control.name}-description`}
                                            variant="caption" 
                                            color="text.secondary"
                                        >
                                            {control.description}
                                        </Typography>
                                    </Box>
                                }
                            />
                        </Tooltip>
                    ))
                )}
            </FormGroup>
        </FormControl>
    );
};

const PrivacySelector = ({ value, onChange, options, disabled, isLoading, isBrandedContent }) => (
    <FormControl fullWidth required>
        <InputLabel id="privacy-level-label">Privacy Level</InputLabel>
        {isLoading ? (
            <Skeleton variant="rectangular" height={56} />
        ) : (
            <Select
                labelId="privacy-level-label"
                id="privacy-level"
                value={value}
                onChange={onChange}
                disabled={disabled}
                aria-describedby="privacy-level-helper-text"
            >
                <MenuItem value="">
                    <em>Select privacy level</em>
                </MenuItem>
                {options.map(option => (
                    <MenuItem 
                        key={option} 
                        value={option}
                        disabled={option === 'SELF_ONLY' && isBrandedContent}
                    >
                        <Box>
                            <Typography>
                                {option.replace(/_/g, ' ').toLowerCase()}
                            </Typography>
                            {option === 'SELF_ONLY' && isBrandedContent && (
                                <Typography variant="caption" color="error">
                                    Not available for branded content
                                </Typography>
                            )}
                        </Box>
                    </MenuItem>
                ))}
            </Select>
        )}
        <FormHelperText id="privacy-level-helper-text">
            {isBrandedContent 
                ? "Branded content cannot be set to private visibility"
                : "Choose who can view your video"
            }
        </FormHelperText>
    </FormControl>
);

const CommercialContentSection = ({ formData, setFormData, disabled }) => (
    <FormControl fullWidth>
        <FormLabel component="legend" id="commercial-content-label">
            Commercial Content Settings
        </FormLabel>
        <FormGroup aria-labelledby="commercial-content-label">
            <FormControlLabel
                control={
                    <Switch
                        checked={formData.is_commercial_content}
                        onChange={(e) => setFormData(prev => ({
                            ...prev,
                            is_commercial_content: e.target.checked,
                            // Reset both checkboxes when commercial content is turned off
                            is_branded_content: e.target.checked ? prev.is_branded_content : false,
                            is_your_brand: e.target.checked ? prev.is_your_brand : false
                        }))}
                        disabled={disabled}
                        inputProps={{
                            'aria-describedby': 'commercial-content-description'
                        }}
                    />
                }
                label="This content promotes a brand, product, or service"
            />
            <Typography 
                id="commercial-content-description"
                variant="caption" 
                color="text.secondary"
            >
                Select this if your content includes any commercial promotions
            </Typography>

            {formData.is_commercial_content && (
                <Box sx={{ ml: 3, mt: 1 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.is_your_brand}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    is_your_brand: e.target.checked
                                }))}
                                disabled={disabled}
                            />
                        }
                        label="Your brand (promoting yourself or your business)"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.is_branded_content}
                                onChange={(e) => {
                                    const isBranded = e.target.checked;
                                    setFormData(prev => ({
                                        ...prev,
                                        is_branded_content: isBranded,
                                        // Auto-adjust privacy level if setting to branded content
                                        privacy_level: isBranded && prev.privacy_level === 'SELF_ONLY'
                                            ? 'PUBLIC_TO_EVERYONE'
                                            : prev.privacy_level
                                    }));
                                }}
                                disabled={disabled}
                            />
                        }
                        label="Branded content (promoting another brand or third party)"
                    />
                    
                    {/* Display the appropriate commercial content label */}
                    <Typography 
                        variant="caption" 
                        color="text.secondary"
                        sx={{ display: 'block', mt: 1 }}
                    >
                        {formData.is_branded_content 
                            ? "Your photo/video will be labeled as 'Paid partnership'"
                            : formData.is_your_brand 
                                ? "Your photo/video will be labeled as 'Promotional content'"
                                : ""}
                    </Typography>
                </Box>
            )}
        </FormGroup>
    </FormControl>
);

export const TikTokPostForm = ({
    videoUrl,
    onSubmit,
    creatorInfo,
    isLoading,
    message,
    onFormValidityChange
}) => {
    const [formData, setFormData] = useState({
        privacy_level: '',
        is_commercial_content: false,
        is_your_brand: false,
        is_branded_content: false,
        allow_comment: true,
        allow_duet: false,
        allow_stitch: false
    });

    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [errors, setErrors] = useState({});

    // Add loading state for creator info
    const [isLoadingCreatorInfo, setIsLoadingCreatorInfo] = useState(true);

    // Validate commercial content settings
    const validateCommercialContent = () => {
        if (formData.is_commercial_content) {
            if (!formData.is_branded_content && !formData.is_your_brand) {
                return "You need to indicate if your content promotes yourself, a third party, or both";
            }
        }
        return null;
    };

    // Update useEffect to monitor form validity
    useEffect(() => {
        const isValid = (() => {
            // If commercial content is selected, check if at least one option is chosen
            if (formData.is_commercial_content) {
                // Return true if either brand option is selected
                if (formData.is_branded_content || formData.is_your_brand) {
                    return true;
                }
                // Return false if no brand option is selected
                return false;
            }

            // If commercial content is not selected, form is valid
            return true;
        })();

        onFormValidityChange?.(isValid);
    }, [
        formData.is_commercial_content,
        formData.is_branded_content,
        formData.is_your_brand,
        onFormValidityChange
    ]);

    // Get terms text based on commercial content settings
    const getTermsText = () => {
        // Determine which links to show based on commercial content settings
        const shouldShowBrandedContentPolicy = formData.is_commercial_content && formData.is_branded_content;
        
        return (
            <Typography component="span">
                By posting, you agree to{' '}
                {shouldShowBrandedContentPolicy && (
                    <>
                        <Link
                            href="https://www.tiktok.com/legal/branded-content-policy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            TikTok's Branded Content Policy
                        </Link>
                        {' and '}
                    </>
                )}
                <Link
                    href="https://www.tiktok.com/legal/music-usage-confirmation"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    TikTok's Music Usage Confirmation
                </Link>
            </Typography>
        );
    };

    // Update handlers to remove isFormValid checks
    const handleSubmit = async () => {
        const error = validateCommercialContent();
        if (error) {
            setErrors({commercial: error});
            return;
        }

        if (!agreedToTerms) {
            setErrors({terms: "You must agree to the terms"});
            return;
        }

        await onSubmit({
            ...formData,
            post_text: message
        });
    };

    // Add new alert for posting restrictions
    const renderRestrictionAlert = () => {
        if (!creatorInfo) return null;

        if (creatorInfo.restriction_message) {
            return (
                <Alert severity="warning" sx={{mb: 2}}>
                    {creatorInfo.restriction_message}
                </Alert>
            );
        }

        if (creatorInfo.remaining_posts === 0) {
            return (
                <Alert severity="warning" sx={{mb: 2}}>
                    You have reached your daily posting limit
                </Alert>
            );
        }

        return null;
    };

    // Add posting limits info
    const renderPostingLimits = () => {
        if (!creatorInfo) return null;

        return (
            <Typography variant="body2" color="text.secondary" sx={{mb: 2}}>
                Posts today: {creatorInfo.posts_today} / {creatorInfo.max_posts_per_day}
            </Typography>
        );
    };

    // Update privacy level validation
    useEffect(() => {
        if (creatorInfo?.requires_private_posting) {
            setFormData(prev => ({
                ...prev,
                privacy_level: 'SELF_ONLY'
            }));
        }
    }, [creatorInfo]);

    const getCommercialContentLabel = () => {
        if (formData.is_your_brand && formData.is_branded_content) {
            return "Your photo/video will be labeled as 'Paid partnership'";
        }
        if (formData.is_your_brand) {
            return "Your photo/video will be labeled as 'Promotional content'";
        }
        if (formData.is_branded_content) {
            return "Your photo/video will be labeled as 'Paid partnership'";
        }
        return '';
    };

    const handleCommercialToggle = (event) => {
        const isCommercial = event.target.checked;
        setFormData(prev => ({
            ...prev,
            is_commercial_content: isCommercial,
            // Reset commercial options when toggling off
            is_your_brand: false,
            is_branded_content: false
        }));
        setErrors({});
    };

    const handleBrandedContentChange = (event) => {
        const isBranded = event.target.checked;
        setFormData(prev => ({
            ...prev,
            is_branded_content: isBranded,
            // Auto-adjust privacy level if setting to branded content
            privacy_level: isBranded && prev.privacy_level === 'SELF_ONLY'
                ? 'PUBLIC_TO_EVERYONE'
                : prev.privacy_level
        }));

        if (isBranded && formData.privacy_level === 'SELF_ONLY') {
            // Show notification about privacy level change
            setErrors({
                privacy: "Visibility has been automatically set to public as branded content cannot be private"
            });
        }
    };

    const handlePrivacyChange = (event) => {
        const newPrivacyLevel = event.target.value;

        // Only restrict privacy level if branded content is selected
        if (formData.is_branded_content && newPrivacyLevel === 'SELF_ONLY') {
            setErrors({
                privacy: "Branded content cannot be set to private visibility"
            });
            return; // Prevent privacy level change
        }

        // Always update the form state when privacy level changes
        setFormData(prev => ({
            ...prev,
            privacy_level: newPrivacyLevel,
            // Reset commercial content settings when switching to private
            is_commercial_content: newPrivacyLevel === 'SELF_ONLY' ? false : prev.is_commercial_content,
            is_your_brand: newPrivacyLevel === 'SELF_ONLY' ? false : prev.is_your_brand,
            is_branded_content: newPrivacyLevel === 'SELF_ONLY' ? false : prev.is_branded_content
        }));
        setErrors({});
    };

    // Determine if private option should be disabled ONLY when branded content is selected
    const isPrivateDisabled = formData.is_branded_content;

    const showCommercialContent = formData.privacy_level === 'PUBLIC_TO_EVERYONE';

    // Get tooltip text for private option
    const getPrivateTooltip = () => {
        if (formData.is_branded_content) {
            return "Branded content visibility cannot be set to private";
        }
        return "";
    };

    return (
        <Box sx={{ position: 'relative' }}>
            {/* Add Restriction Alert and Posting Limits */}
            {!isLoading && (
                <>
                    {renderRestrictionAlert()}
                    {renderPostingLimits()}
                </>
            )}

            {/* Form Content */}
            <Stack spacing={3} sx={{ 
                opacity: isLoading ? 0.7 : 1,
                pointerEvents: isLoading ? 'none' : 'auto'
            }}>
                {/* Privacy Selector with Loading State */}
                {isLoading ? (
                    <Skeleton variant="rectangular" height={56} />
                ) : (
                    <PrivacySelector
                        value={formData.privacy_level}
                        onChange={handlePrivacyChange}
                        options={creatorInfo?.privacy_level_options || []}
                        disabled={isLoading}
                        isLoading={isLoading}
                        isBrandedContent={formData.is_branded_content}
                    />
                )}

                {/* Interaction Settings with Loading State */}
                {isLoading ? (
                    <Skeleton variant="rectangular" height={150} />
                ) : (
                    <InteractionSettings
                        formData={formData}
                        setFormData={setFormData}
                        creatorInfo={creatorInfo}
                        disabled={isLoading}
                    />
                )}

                {/* Commercial Content Section with Loading State */}
                {isLoading ? (
                    <Skeleton variant="rectangular" height={100} />
                ) : (
                    <CommercialContentSection
                        formData={formData}
                        setFormData={setFormData}
                        disabled={isLoading}
                    />
                )}

                {/* Terms Agreement with Loading State */}
                {isLoading ? (
                    <Skeleton variant="rectangular" height={40} />
                ) : (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={agreedToTerms}
                                onChange={(e) => setAgreedToTerms(e.target.checked)}
                                disabled={isLoading}
                            />
                        }
                        label={getTermsText()}
                    />
                )}
            </Stack>

            {/* Global Loading Overlay */}
            {isLoading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                        zIndex: 1,
                    }}
                >
                    <CircularProgress />
                    <Typography variant="body2" color="text.secondary">
                        Loading form...
                    </Typography>
                </Box>
            )}

            {/* Error Display */}
            {errors && Object.keys(errors).length > 0 && (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {Object.values(errors).map((error, index) => (
                        <Typography key={index}>{error}</Typography>
                    ))}
                </Alert>
            )}
        </Box>
    );
};